import { Reducer } from 'redux';
import { GuideDetailsState, GuidesActionTypes} from './guides-types';

export const guideDetailsInitialState: GuideDetailsState = {
  guide: undefined,
  loadingGuide: false,
  orgsLimit: 10,
  orgsOffset: 0,
  loadingOrgs: false,
  searchingOrgs: false,
  guideSearchOrgs: [],
};

export const guideDetailsReducer: Reducer<GuideDetailsState> = (state = guideDetailsInitialState, action) => {
  switch (action.type) {
    case GuidesActionTypes.SELECT: {
      return { ...state, loadingGuide: false, guide: action.payload.org, orgsList: undefined };
    }
    case GuidesActionTypes.GET: {
      return { ...state, loadingGuide: true, guide: undefined };
    }
    case GuidesActionTypes.GET_SUCCESS: {
      return { ...state, loadingGuide: false, guide: action.payload.guide };
    }
    case GuidesActionTypes.GET_ERROR: {
      return { ...state, loadingGuide: false, guide: undefined };
    }
    case GuidesActionTypes.UPDATE_GUIDE: {
      return { ...state, loadingGuide: true };
    }
    case GuidesActionTypes.UPDATE_GUIDE_SUCCESS: {
      return { ...state, loadingGuide: false, guide: action.payload.guide };
    }
    case GuidesActionTypes.UPDATE_GUIDE_ERROR: {
      return { ...state, loadingGuide: false };
    }
    case GuidesActionTypes.LIST_ORGS: {
      const { limit, offset } = action.payload;
      return { ...state, loadingOrgs: true, orgsLimit: limit, orgsOffset: offset };
    }
    case GuidesActionTypes.LIST_ORGS_SUCCESS: {
      return { ...state, loadingOrgs: false, orgsList: action.payload };
    }
    case GuidesActionTypes.LIST_ORGS_ERROR: {
      return { ...state, loadingOrgs: false, orgsList: undefined };
    }
    case GuidesActionTypes.SEARCH_ORGS: {
      return { ...state, searchingOrgs: true };
    }
    case GuidesActionTypes.SEARCH_ORGS_SUCCESS: {
      return { ...state, searchingOrgs: false, guideSearchOrgs: action.payload.data };
    }
    case GuidesActionTypes.SEARCH_ORGS_ERROR: {
      return { ...state, searchingOrgs: false, guideSearchOrgs: [] };
    }
    default: {
      return state;
    }
  }
};
