import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  CircularProgress,
  DataTable,
  DialogContainer,
  Drawer,
  MenuButton,
  TableBody,
  TableColumn,
  TableHeader,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
} from 'react-md';
import { IUser, OrganizationOutput, OrganizationUser, OrganizationUserRole } from '@mmc-csm/shared';
import { connect } from 'react-redux';
import { TextFieldComponent } from 'react-md/lib/TextFields/TextField';
import { OrgDetailsState } from '../../store/orgs/orgs-types';
import OrgUserItems from './OrgUserItems';
import { InfoMessage } from '../utils/InfoMessage';
import { ApplicationState } from '../../store';
import { changeUserAddOnServices, listUserSettings, resetPasswordCode } from '../../store/orgs/orgs-actions';
import { addToast } from '../../store/toasts/toasts-actions';
import { config } from '../../config';

type OrgsDataTableProps = Pick<OrgDetailsState, 'usersList' | 'changingUserAddOnServices'> & {
  currentUser?: IUser;
  id: string;
  onAddToast: typeof addToast;
  onChangeRole: (id: number, username: string, roleId: number) => void;
  onChangeUserAddOnServices: typeof changeUserAddOnServices;
  onLoadUserSettings: typeof listUserSettings,
  onPagination: (start: number, rowsPerPage: number) => void;
  onResetPasswordCode: typeof resetPasswordCode,
  orgId?: OrganizationOutput['id'],
  passwordCode?: string;
  passwordCodeGenerating?: boolean;
  roles: OrganizationUserRole[];
};

const rowsPerPageItems = [10, 15, 30, 50, 100];

type AddOnName = keyof OrganizationUser['addOns'];

const defaultList = {
  data: undefined,
  total: 0,
  limit: 10,
  offset: 0,
};

const OrgUsersDataTable: React.FC<OrgsDataTableProps> = ({
  changingUserAddOnServices,
  currentUser,
  id,
  onAddToast,
  onChangeRole,
  onChangeUserAddOnServices,
  onLoadUserSettings,
  onPagination,
  onResetPasswordCode,
  orgId,
  passwordCode,
  passwordCodeGenerating,
  roles,
  usersList = defaultList,
}) => {
  const textField = useRef<TextFieldComponent>();

  const users = usersList.data || [];
  const rowsCount = usersList.total || 0;
  const rowsPerPage = usersList.limit || defaultList.limit;
  const offset = usersList.offset || 0;
  const page = offset / rowsPerPage + 1;

  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(users[0]);
  const [resetPasswordVisible, setResetPasswordVisibility] = useState<boolean>(false);

  const handleDrawer = () => {
    if (open) setVisible(false);
    else setVisible(true);

    setOpen(!open);
  };

  const handleVisibility = () => {
    setVisible(false);
    setOpen(false);
  };

  const drawerCallback = (roleId: number, addOns: Partial<OrganizationUser['addOns']>) => {
    // this method name is somewhat misleading, it fact it changes org user, so we're changing
    // both role and addOns here in one call
    onChangeUserAddOnServices(orgId!,
      { ...selectedUser, role: { id: roleId }, addOns: { ...selectedUser.addOns, ...addOns } });
    setVisible(false);
  };

  const openDrawer = (user: OrganizationUser) => {
    setSelectedUser(user);
    onLoadUserSettings(orgId!, user.id);
    handleDrawer();
  };

  const handleResetPassword = useCallback(
    () => {
      if (currentUser) {
        onResetPasswordCode(selectedUser.id, currentUser.email);
        setResetPasswordVisibility(true);
      }
    }, [currentUser, onResetPasswordCode, selectedUser, setResetPasswordVisibility],
  );

  const copyToClipboard = useCallback((e: any) => {
    if (!textField.current) {
      return;
    }
    // We know that the input does have select method
    // @ts-ignore
    textField.current.getField()!.select();
    document.execCommand('copy');
    e.currentTarget.focus();
    onAddToast({ text: 'Copied!' });
  }, [onAddToast, textField]);

  const resetPasswordActions = useMemo(
    () => {
      const result = [];
      result.push({ children: 'Close', onClick: () => setResetPasswordVisibility(false) });
      if (!passwordCodeGenerating) {
        result.push({ children: 'Copy to Clipboard', onClick: copyToClipboard, primary: true });
      }
      return result;
    }, [passwordCodeGenerating, copyToClipboard],
  );

  const resetPasswordLink = useMemo(
    () => {
      if (selectedUser && passwordCode) {
        const query = new URLSearchParams({ code: passwordCode, username: selectedUser.username });
        return `${config.baseAppURL}/reset-password?${query}`;
      }
      return '';
    }, [passwordCode, selectedUser],
  );

  const [selectedUsers, setSelectedUsers] = useState<OrganizationUser[]>([]);

  const handleRowToggle = useCallback((rowId: number, checked: boolean) => {
    // clicked on header checkbox
    if (rowId === 0) {
      setSelectedUsers(checked ? users : []);
    } else {
      setSelectedUsers(currentlySelectedUsers => {
        const clickedUser = users[rowId - 1];
        return checked
          ? [...currentlySelectedUsers, clickedUser]
          : currentlySelectedUsers.filter(user => user.id !== clickedUser.id);
      });
    }
  }, [users]);


  const tableRef = useRef(null);

  const bulkToggleAddOn = useCallback((addOnName: AddOnName, enable: boolean) => {
    selectedUsers.forEach(user => {
      onChangeUserAddOnServices(orgId!, { ...user, addOns: { ...user.addOns, [addOnName]: enable } });
    });
    setSelectedUsers([]);
    if (tableRef.current) {
      // these's no way to control table state via methods, so I have to directly modify its inner state
      (tableRef.current! as any).state.selectedRows = (tableRef.current! as any).state.selectedRows.map(() => false);
      (tableRef.current! as any).state.allSelected = false;
      (tableRef.current! as any).state.indeterminate = false;
    }
  }, [onChangeUserAddOnServices, selectedUsers, orgId]);

  return (
    <>
      {selectedUsers.length > 0 && (
        <MenuButton
          anchor={{
            x: 'inner left',
            y: 'bottom',
          }}
          fullWidth={false}
          fillViewportWidth={false}
          id="selected-users-menu"
          raised
          primary
          menuItems={[
            {
              primaryText: 'Email Service',
              nestedItems: [
                { primaryText: 'Enable', onClick: () => bulkToggleAddOn('emailService', true) },
                { primaryText: 'Disable', onClick: () => bulkToggleAddOn('emailService', false) },
              ],
            },
            {
              primaryText: 'Mileage Tracking',
              nestedItems: [
                { primaryText: 'Enable', onClick: () => bulkToggleAddOn('mileageTracking', true) },
                { primaryText: 'Disable', onClick: () => bulkToggleAddOn('mileageTracking', false) },
              ],
            },
            {
              primaryText: 'Ongoing Check-Ins',
              nestedItems: [
                { primaryText: 'Enable', onClick: () => bulkToggleAddOn('activeCheckIn', true) },
                { primaryText: 'Disable', onClick: () => bulkToggleAddOn('activeCheckIn', false) },
              ],
            },
          ]}
          iconChildren="edit"
        >
          Bulk Edit
        </MenuButton>
      )}
      <DataTable ref={tableRef} baseId={id} indeterminate responsive={false} className="OrgUsersDataTable" onRowToggle={handleRowToggle}>
        <TableHeader>
          <TableRow>
            <TableColumn className="ColumnUserName">Name</TableColumn>
            <TableColumn className="ColumnUserEmail">Email</TableColumn>
            <TableColumn className="ColumnUserPhone">Phone</TableColumn>
            <TableColumn className="ColumnUserRole">Role</TableColumn>

          </TableRow>
        </TableHeader>

        <TableBody>
          {users.map(user => (
            <TableRow key={user.id} onClick={() => openDrawer(user)}>
              <TableColumn className="ColumnUserName">{user.fullName}</TableColumn>
              <TableColumn className="ColumnUserEmail">{user.username}</TableColumn>
              <TableColumn className="ColumnUserPhone">{user.phone}</TableColumn>
              <TableColumn className="ColumnUserRole">{user.integrationsOnly ? 'Integration Only' : user.role.name}</TableColumn>

            </TableRow>
          ))}
        </TableBody>
        <Drawer
          classname="OrgDrawer"
          type={Drawer.DrawerTypes.TEMPORARY}
          hideBackdrop
          visible={visible}
          position="right"
          onVisibilityChange={handleVisibility}
          header={(
            <Toolbar
              actions={(
                <OrgUserItems
                  onResetPassword={handleResetPassword}
                  roles={roles}
                  onChange={drawerCallback}
                  onHide={() => setVisible(false)}
                  user={selectedUser}
                />
              )}
              className="OrgDrawer"
            />
            )}
          style={{ width: 480, zIndex: 999 }}
        />
        <TablePagination
          rows={rowsCount}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageItems={rowsPerPageItems}
          onPagination={onPagination}
        />
        {(rowsCount === 0) && <p className='NoResults'>No Results Found.</p>}
        <DialogContainer
          actions={resetPasswordActions}
          focusOnMount={false}
          id="skeleton-keys-dialog"
          onHide={() => setResetPasswordVisibility(false)}
          title="Password Reset Link"
          visible={resetPasswordVisible}
          style={{ zIndex: 999 }}
          width={400}
        >
          {passwordCodeGenerating && <CircularProgress id="reset-password-code" />}
          {!passwordCodeGenerating && (
            <>
              <InfoMessage>The link will expire in 1 day.</InfoMessage>
              <TextField
                id="txt-pwd-reset-link"
                ref={field => {
                  textField.current = field as unknown as TextFieldComponent;
                }}
                value={resetPasswordLink}
              />
            </>
          )}
        </DialogContainer>
      </DataTable>
    </>
  );
};

const mapStateToProps = ({ auth, orgs, orgDetails }: ApplicationState) => ({
  currentUser: auth.user,
  passwordCodeGenerating: orgs.passwordCodeGenerating,
  passwordCode: orgs.resetPasswordCode,
  changingUserAddOnServices: orgDetails.changingUserAddOnServices,
});

const mapDispatchToProps = {
  onResetPasswordCode: resetPasswordCode,
  onAddToast: addToast,
  onLoadUserSettings: listUserSettings,
  onChangeUserAddOnServices: changeUserAddOnServices,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgUsersDataTable);
