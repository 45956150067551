import { Reducer } from 'redux';
import { GuidesActionTypes, GuidesState } from './guides-types';

export const guidesInitialState: GuidesState = {
  guideList: undefined,
  limit: 10,
  offset: 0,
  search: undefined,
  loading: false,
  creatingGuide: false,
};

export const guidesReducer: Reducer<GuidesState> = (state = guidesInitialState, action) => {
  switch (action.type) {
    case GuidesActionTypes.LIST: {
      const { limit, offset, search } = action.payload;
      return { ...state, loading: true, limit, offset, search };
    }
    case GuidesActionTypes.LIST_SUCCESS: {
      return { ...state, loading: false, guideList: action.payload };
    }
    case GuidesActionTypes.LIST_ERROR: {
      return { ...state, loading: false };
    }
    case GuidesActionTypes.CREATE_GUIDE: {
      return { ...state, creatingGuide: true };
    }
    case GuidesActionTypes.CREATE_GUIDE_SUCCESS: {
      return { ...state, creatingGuide: false };
    }
    case GuidesActionTypes.CREATE_GUIDE_ERROR: {
      return { ...state, creatingGuide: false };
    }
    case GuidesActionTypes.UPDATE_GUIDE_SUCCESS: {
      const { guideList } = state;
      if (!guideList) {
        return state;
      }
      const data = guideList.data.map(guide => (guide.id === action.payload.guide.id
        ? { ...guide, ...action.payload.guide }
        : guide));
      return { ...state, guideList: { ...guideList, data } };
    }
    case GuidesActionTypes.DELETE_GUIDE_SUCCESS: {
      const { guideList } = state;
      if (!guideList) {
        return state;
      }
      const data = guideList.data.filter(guide => guide.id !== action.payload.id);
      return { ...state, guideList: { ...guideList, data } };
    }
    default: {
      return state;
    }
  }
};
