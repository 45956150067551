import { Reducer } from 'redux';
import { OrganizationUser } from '@mmc-csm/shared';
import { OrgDetailsState, OrgsActionTypes } from './orgs-types';
import { GuidesActionTypes } from '../guides/guides-types';

export const orgDetailsInitialState: OrgDetailsState = {
  org: undefined,
  user: undefined,
  loadingOrg: false,
  usersLimit: 10,
  usersOffset: 0,
  loadingUsers: false,
  loadingUserSettings: false,
  usersList: undefined,
  rolesList: undefined,
  settingsList: [],
  userSettingsList: [],
  changingSeats: false,
  changingStatus: false,
  changingName: false,
  changingRole: false,
  changingSetting: false,
  changingUserSetting: false,
  changingFeature: false,
  changingAddOnServices: false,
  changingUserAddOnServices: false,
  extendingTrial: false,
  upgradingPlan: false,
  loadingGuides: false,
  guidesOffset: 0,
  guidesLimit: 10,
  guidesList: undefined,
  assignSearch: '',
  assignExistingGuides: [],
  assignGuidesList: [],
  assignGuidesListLoading: false,
};

export const orgDetailsReducer: Reducer<OrgDetailsState> = (state = orgDetailsInitialState, action) => {
  switch (action.type) {
    case OrgsActionTypes.SELECT: {
      return { ...state, loadingOrg: false, org: action.payload.org, usersList: undefined, rolesList: undefined };
    }
    case OrgsActionTypes.GET: {
      return { ...state, loadingOrg: true, org: undefined };
    }
    case OrgsActionTypes.GET_SUCCESS: {
      return { ...state, loadingOrg: false, org: action.payload.org };
    }
    case OrgsActionTypes.GET_ERROR: {
      return { ...state, loadingOrg: false, org: undefined };
    }
    case OrgsActionTypes.LIST_USERS: {
      const { limit, offset } = action.payload;
      return { ...state, loadingUsers: true, usersLimit: limit, usersOffset: offset };
    }
    case OrgsActionTypes.LIST_USERS_SUCCESS: {
      return { ...state, loadingUsers: false, usersList: action.payload };
    }
    case OrgsActionTypes.LIST_USERS_ERROR: {
      return { ...state, loadingUsers: false, usersList: undefined };
    }
    case OrgsActionTypes.LIST_GUIDES: {
      const { limit, offset } = action.payload;
      return { ...state, loadingGuides: true, guidesLimit: limit, guidesOffset: offset };
    }
    case OrgsActionTypes.LIST_GUIDES_SUCCESS: {
      return { ...state, loadingGuides: false, guidesList: action.payload };
    }
    case OrgsActionTypes.LIST_GUIDES_ERROR: {
      return { ...state, loadingGuides: false, guidesList: undefined };
    }
    case OrgsActionTypes.LIST_ROLES_SUCCESS: {
      return { ...state, rolesList: action.payload };
    }
    case OrgsActionTypes.LIST_ROLES_ERROR: {
      return { ...state, rolesList: undefined };
    }
    case OrgsActionTypes.LIST_SETTINGS_SUCCESS: {
      return { ...state, settingsList: action.payload };
    }
    case OrgsActionTypes.LIST_SETTINGS_ERROR: {
      return { ...state, settingsList: [] };
    }
    case OrgsActionTypes.LIST_USER_SETTINGS: {
      return { ...state, loadingUserSettings: true, userSettingsList: [] };
    }
    case OrgsActionTypes.LIST_USER_SETTINGS_SUCCESS: {
      return { ...state, loadingUserSettings: false, userSettingsList: action.payload };
    }
    case OrgsActionTypes.LIST_USER_SETTINGS_ERROR: {
      return { ...state, loadingUserSettings: false, userSettingsList: [] };
    }
    case OrgsActionTypes.CHANGE_SEATS: {
      return { ...state, changingSeats: true };
    }
    case OrgsActionTypes.CHANGE_SEATS_SUCCESS: {
      const org = { ...state.org!, userLimit: action.payload.seats };
      return { ...state, changingSeats: false, org };
    }
    case OrgsActionTypes.CHANGE_SEATS_ERROR: {
      return { ...state, changingSeats: false };
    }
    case OrgsActionTypes.CHANGE_STATUS: {
      return { ...state, changingStatus: true };
    }
    case OrgsActionTypes.CHANGE_STATUS_SUCCESS: {
      const org = { ...state.org!, isActive: action.payload.isActive };
      return { ...state, changingStatus: false, org };
    }
    case OrgsActionTypes.CHANGE_STATUS_ERROR: {
      return { ...state, changingStatus: false };
    }
    case OrgsActionTypes.CHANGE_NAME: {
      return { ...state, changingName: true };
    }
    case OrgsActionTypes.CHANGE_NAME_SUCCESS: {
      const org = { ...state.org!, name: action.payload.name };
      return { ...state, changingName: false, org };
    }

    case OrgsActionTypes.CHANGE_ROLE: {
      return { ...state, changingRole: true };
    }
    case OrgsActionTypes.CHANGE_ROLE_SUCCESS: {
      const org = { ...state.org!, roleId: action.payload.roleId };
      return { ...state, changingRole: false, org };
    }
    case OrgsActionTypes.CHANGE_SETTING: {
      return { ...state, changingSetting: true };
    }
    case OrgsActionTypes.CHANGE_SETTING_SUCCESS: {
      return {
        ...state,
        changingSetting: false,
        settingsList: state.settingsList.map(s => (s.id === action.payload.id ? action.payload : s)),
      };
    }
    case OrgsActionTypes.CHANGE_USER_SETTING: {
      return { ...state, changingUserSetting: true };
    }
    case OrgsActionTypes.CHANGE_USER_SETTING_SUCCESS: {
      return {
        ...state,
        changingUserSetting: false,
        userSettingsList: state.userSettingsList.map(s => (s.id === action.payload.id ? action.payload : s)),
      };
    }
    case OrgsActionTypes.CHANGE_NAME_ERROR: {
      return { ...state, changingName: false };
    }
    case OrgsActionTypes.CHANGE_FEATURE: {
      return { ...state, changingFeature: true };
    }
    case OrgsActionTypes.CHANGE_FEATURE_SUCCESS: {
      return { ...state, changingFeature: false };
    }
    case OrgsActionTypes.CHANGE_FEATURE_ERROR: {
      return { ...state, changingFeature: false };
    }
    case OrgsActionTypes.CHANGE_ADD_ON_SERVICES: {
      return { ...state, changingAddOnServices: true };
    }
    case OrgsActionTypes.CHANGE_ADD_ON_SERVICES_SUCCESS: {
      const org = { ...state.org!, addOnServices: action.payload.addOnServices };
      return { ...state, changingAddOnServices: false, org };
    }
    case OrgsActionTypes.CHANGE_ADD_ON_SERVICES_ERROR: {
      return { ...state, changingAddOnServices: false };
    }
    case OrgsActionTypes.CHANGE_USER_ADD_ON_SERVICES: {
      return { ...state, changingUserAddOnServices: true };
    }
    case OrgsActionTypes.CHANGE_USER_ADD_ON_SERVICES_SUCCESS: {
      const users: OrganizationUser[] = state.usersList
        ? state.usersList.data.map(user => {
          if (user.id === action.payload.id) {
            return {
              ...action.payload,
            };
          }
          return user;
        })
        : [];
      return { ...state,
        changingUserAddOnServices: false,
        usersList: state.usersList ? { ...state.usersList, data: users } : undefined };
    }
    case OrgsActionTypes.CHANGE_USER_ADD_ON_SERVICES_ERROR: {
      return { ...state, changingUserAddOnServices: false };
    }
    case OrgsActionTypes.EXTEND_TRIAL: {
      return { ...state, extendingTrial: true };
    }
    case OrgsActionTypes.EXTEND_TRIAL_SUCCESS: {
      const org = { ...state.org!, trialExpiresAt: action.payload.trialExpiresAt };
      return { ...state, extendingTrial: false, org };
    }
    case OrgsActionTypes.EXTEND_TRIAL_ERROR: {
      return { ...state, extendingTrial: false };
    }
    case OrgsActionTypes.UPGRADE_PLAN: {
      return { ...state, upgradingPlan: true };
    }
    case OrgsActionTypes.UPGRADE_PLAN_SUCCESS: {
      const org = { ...state.org!, plan: action.payload.plan };
      return { ...state, upgradingPlan: false, org };
    }
    case OrgsActionTypes.UPGRADE_PLAN_ERROR: {
      return { ...state, upgradingPlan: false };
    }
    case GuidesActionTypes.UPDATE_GUIDE_SUCCESS: {
      const { guidesList } = state;
      if (!guidesList) {
        return state;
      }
      const data = guidesList.data.map(guide => (guide.id === action.payload.guide.id
        ? { ...guide, ...action.payload.guide }
        : guide));
      return { ...state, guidesList: { ...guidesList, data } };
    }
    case OrgsActionTypes.ASSIGN_GUIDES_INITIALIZE_SUCCESS: {
      return { ...state, assignExistingGuides: action.payload.guides };
    }
    case OrgsActionTypes.ASSIGN_GUIDES_SEARCH: {
      return { ...state, assignGuidesListLoading: true };
    }
    case OrgsActionTypes.ASSIGN_GUIDES_SEARCH_SUCCESS: {
      return { ...state, assignGuidesListLoading: false, assignGuidesList: action.payload.guides };
    }
    case OrgsActionTypes.ASSIGN_GUIDES_SEARCH_ERROR: {
      return { ...state, assignGuidesListLoading: false, assignGuidesList: [] };
    }
    default: {
      return state;
    }
  }
};
