import React, { FC, useMemo } from 'react';
import { DialogContainer } from 'react-md';

export interface GuideRemoveConfirmationModalProps {
  guideName: string,
  onCancel: () => void;
  onRemove: () => void;
}

const GuideRemoveConfirmationModal: FC<GuideRemoveConfirmationModalProps> = ({ guideName, onCancel, onRemove }) => {
  const actions = useMemo(() => [
    { primary: true, children: 'Cancel', onClick: onCancel },
    { secondary: true, children: 'Remove', onClick: onRemove },
  ], [onCancel, onRemove]);

  return (
    <DialogContainer
      className="GuideDeleteConfirmationModal"
      id="remove-guide-confirmation-modal"
      onHide={onCancel}
      actions={actions}
      title={`Remove ${guideName ? `"${guideName}"` : 'this guide'} from the Org?`}
      portal
      visible
    />
  );
};

export default GuideRemoveConfirmationModal;
