import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import { OrgsSearchBar } from '../orgs/OrgsSearchBar';
import { DataTableSpinner } from '../utils/DataTableSpinner';
import { GuidesDataTable } from './GuidesDataTable';
import { ApplicationState } from '../../store';
import { listGuides, selectGuide } from '../../store/guides/guides-actions';
import { GuidesState } from '../../store/guides/guides-types';
import CreateEditGuideDrawer from './CreateEditGuideDrawer';

export interface GuidesProps extends Pick<GuidesState, 'loading' | 'guideList' | 'limit' | 'offset' | 'search' | 'filter'> {
  onListGuides: typeof listGuides;
  onSelectGuide: typeof selectGuide,
}

const Guides: FC<GuidesProps> = ({ loading, guideList, onListGuides, onSelectGuide, limit, offset }) => {
  const [start, setStart] = useState(offset);
  const [pageSize, setPageSize] = useState(limit);
  const [order, setOrder] = useState('');
  const [search, setSearch] = useState<string>('');

  const reload = useCallback(() => {
    const searchString = search ? `{"$or":[{"frigadeFlowId":{"$in":"${search}"}},{"internalName":{"$in":"${search}"}},{"displayName":{"$in":"${search}"}}]}` : undefined;
    onListGuides({ order, limit: pageSize, offset: start, search: searchString });
  }, [order, pageSize, search, start, onListGuides]);

  useEffect(() => {
    reload();
  }, [reload]);

  const handlePagination = useCallback((start: number, rowsPerPage: number) => {
    setStart(start);
    setPageSize(rowsPerPage);
    window.scrollTo(0, 0);
  }, []);

  const [addGuideModalVisible, setAddGuideModalVisible] = useState(false);

  return (
    <div>
      <div className="OrgsSearchBar GuidesSearchBar">
        <Button flat primary onClick={() => setAddGuideModalVisible(true)}>Add new</Button>
        <OrgsSearchBar defaultValue={search} onSearch={setSearch} />
      </div>
      <DataTableSpinner id="orgs-dt-spinner" loading={loading}>
        <GuidesDataTable
          id="guides-dt-table"
          guideList={guideList}
          onPagination={handlePagination}
          onGuideClicked={onSelectGuide}
          onHeaderClicked={setOrder}
        />
      </DataTableSpinner>
      {addGuideModalVisible && (
        <CreateEditGuideDrawer onCreated={() => reload()} onClose={() => setAddGuideModalVisible(false)} />
      )}
    </div>
  );
};

const mapStateToProps = ({ guides }: ApplicationState) => ({
  limit: guides.limit,
  offset: guides.offset,
  search: guides.search,
  loading: guides.loading,
  guideList: guides.guideList,
});

const mapDispatchToProps = {
  onListGuides: listGuides,
  onSelectGuide: selectGuide,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Guides);
