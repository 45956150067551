import React, {useState} from 'react';
import {DataTable, TableBody, TableColumn, TableHeader, TablePagination, TableRow} from 'react-md';
import {GuideOutput, OrganizationOutput, OrganizationUser} from '@mmc-csm/shared';
import {OrgDetailsState} from '../../store/orgs/orgs-types';
import CreateEditGuideDrawer from '../guides/CreateEditGuideDrawer';

type OrgsGuidesDataTableProps = Pick<OrgDetailsState, 'guidesList'> & {
  id: string;
  orgId: OrganizationOutput["id"];
  onPagination: (start: number, rowsPerPage: number) => void;
  onDeleted: () => void,
};

const rowsPerPageItems = [10, 15, 30, 50, 100];

type AddOnName = keyof OrganizationUser['addOns'];

const defaultList = {
  data: undefined,
  total: 0,
  limit: 10,
  offset: 0,
};

const OrgGuidesDataTable: React.FC<OrgsGuidesDataTableProps> = ({
  id,
  orgId,
  onPagination,
  guidesList = defaultList,
  onDeleted,
}) => {
  const guides = guidesList.data || [];
  const rowsCount = guidesList.total || 0;
  const rowsPerPage = guidesList.limit || defaultList.limit;
  const offset = guidesList.offset || 0;
  const page = offset / rowsPerPage + 1;

  const [selectedGuide, setSelectedGuide] = useState<GuideOutput | undefined>(undefined);

  return (
    <>
      <DataTable baseId={id} plain responsive={false} className="OrgGuidesDataTable">
        <TableHeader>
          <TableRow>
            <TableColumn className="ColumnFrigadeId">Frigade ID</TableColumn>
            <TableColumn className="ColumnGuideId">Guide ID</TableColumn>
            <TableColumn className="ColumnGuideType">Type</TableColumn>
            <TableColumn className="ColumnName">Name</TableColumn>
            <TableColumn className="ColumnDisplayName">Name in UI</TableColumn>
          </TableRow>
        </TableHeader>

        <TableBody>
          {guides.map(guide => (
            <TableRow key={guide.id} onClick={() => setSelectedGuide(guide)}>
              <TableColumn className="ColumnFrigadeId">{guide.frigadeFlowId}</TableColumn>
              <TableColumn className="ColumnGuideId">{guide.id}</TableColumn>
              <TableColumn className="ColumnGuideType">{guide.type}</TableColumn>
              <TableColumn className="ColumnName">{guide.internalName}</TableColumn>
              <TableColumn className="ColumnDisplayName">{guide.displayName}</TableColumn>
            </TableRow>
          ))}
        </TableBody>
        <TablePagination
          rows={rowsCount}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageItems={rowsPerPageItems}
          onPagination={onPagination}
        />
        {(rowsCount === 0) && <p className='NoResults'>No Results Found.</p>}
        {selectedGuide && (
          <CreateEditGuideDrawer
            guide={selectedGuide}
            orgId={orgId}
            orgIds={selectedGuide.orgIds || []}
            onClose={() => setSelectedGuide(undefined)}
            onDeleted={onDeleted}
          />
        )}
      </DataTable>
    </>
  );
};

export default OrgGuidesDataTable;
