import React from 'react';
import { DataTable, TableBody, TableColumn, TableHeader, TablePagination, TableRow } from 'react-md';
import { GuideOutput, IUser } from '@mmc-csm/shared';
import { GuideDetailsState } from '../../store/guides/guides-types';

type OrgsDataTableProps = Pick<GuideDetailsState, 'orgsList'> & {
  currentUser?: IUser;
  id: string;
  // onAddToast: typeof addToast;
  onPagination: (start: number, rowsPerPage: number) => void;
  guide: GuideOutput,
};

const rowsPerPageItems = [10, 15, 30, 50, 100];

const defaultList = {
  data: undefined,
  total: 0,
  limit: 10,
  offset: 0,
};

const GuideOrgsDataTable: React.FC<OrgsDataTableProps> = ({
  id,
  onPagination,
  // guide,
  orgsList = defaultList,
}) => {
  const orgs = orgsList.data || [];
  const rowsCount = orgsList.total || 0;
  const rowsPerPage = orgsList.limit || defaultList.limit;
  const offset = orgsList.offset || 0;
  const page = offset / rowsPerPage + 1;

  return (
    <>
      <DataTable baseId={id} plain responsive={false}>
        <TableHeader>
          <TableRow>
            <TableColumn className="ColumnOrgName">Org</TableColumn>
          </TableRow>
        </TableHeader>

        <TableBody>
          {orgs.map(org => (
            <TableRow key={org.id}>
              <TableColumn className="ColumnOrgName">{org.name}</TableColumn>
            </TableRow>
          ))}
        </TableBody>
        <TablePagination
          rows={rowsCount}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageItems={rowsPerPageItems}
          onPagination={onPagination}
        />
        {(rowsCount === 0) && <p className='NoResults'>No Results Found.</p>}
      </DataTable>
    </>
  );
};

export default GuideOrgsDataTable;
