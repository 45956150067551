import React, { FC, ReactText, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, CircularProgress, DialogContainer, FontIcon, TextField } from 'react-md';
import { GuideOutput, OrganizationOutput } from '@mmc-csm/shared';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { assignGuidesInitialize, assignGuidesSearch } from '../../store/orgs/orgs-actions';

export interface OrgGuidesModalProps {
  loading: boolean,
  orgId: OrganizationOutput['id'],
  existingGuides: GuideOutput[],
  guides: GuideOutput[],
  onSave?: (guideIds: GuideOutput['id'][]) => void,
  onInitializeOrgGuides: (orgId: OrganizationOutput['id']) => void,
  onClose: () => void;
  onSearch: typeof assignGuidesSearch,
}

const OrgGuidesModal: FC<OrgGuidesModalProps> = ({ loading, orgId, existingGuides, guides, onSave, onInitializeOrgGuides, onClose, onSearch }) => {
  useEffect(() => {
    onInitializeOrgGuides(orgId);
  }, [orgId, onInitializeOrgGuides]);

  const [guideIds, setGuideIds] = useState<GuideOutput['id'][]>([]);
  useEffect(() => {
    setGuideIds(existingGuides.map(({ id }) => id));
  }, [existingGuides]);

  const [search, setSearch] = useState('');
  useEffect(() => {
    onSearch(orgId, '');
  }, [onSearch, orgId]);

  const handleSearchChange = useCallback((value: ReactText) => setSearch(value as string), []);

  const handleSave = useCallback(() => {
    if (onSave) {
      onSave(guideIds);
    }
  }, [guideIds, onSave]);

  const actions = useMemo(
    () => {
      const result = [];
      result.push({ children: 'Cancel', onClick: onClose });
      result.push({ children: 'Save', onClick: handleSave, primary: true });
      return result;
    }, [handleSave, onClose],
  );

  const [buttonState, setButtonState] = useState(false);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      onSearch(orgId, search);
      setButtonState(true);
    }
  };

  const handleClick = () => {
    if (!buttonState) {
      onSearch(orgId, search);
      setButtonState(true);
    } else {
      setSearch('');
      onSearch(orgId, '');
      setButtonState(false);
    }
  };

  const buttonIcon = () => {
    if (buttonState) return <FontIcon>close</FontIcon>;
    return <FontIcon>search</FontIcon>;
  };

  const handleGuideChange = (guideId: GuideOutput['id']) => {
    setGuideIds(guideIds => {
      if (guideIds.includes(guideId)) {
        return guideIds.filter(id => id !== guideId);
      }
      return [...guideIds, guideId];
    });
  };

  return (
    <DialogContainer
      actions={actions}
      focusOnMount
      id="orgs-to-guide-dialog"
      onHide={() => onClose()}
      title="Assign Guides"
      visible
      style={{ zIndex: 999 }}
      width={400}
    >
      <div className="OrgGuidesModal__subTitle">
        All users with assigning abilities from this org will be able to see and assign the following guides to their users.
      </div>
      <TextField
        id="txt-guides-search"
        onChange={handleSearchChange}
        value={search}
        inlineIndicator={<Button onClick={handleClick}>{buttonIcon()}</Button>}
        onKeyDown={handleKeyDown}
        fullWidth
        placeholder="Search by Name or Flow Id"
      />
      <div className="OrgGuidesModal__list">
        {loading ? <CircularProgress id="org-guides-loading" scale={2} /> : (
          guides.map(guide => (
            <div className="OrgGuidesModal__item" key={guide.id}>
              <div className="OrgGuidesModal__item-info">
                {guide.internalName && <div className="OrgGuidesModal__item-info-title">{guide.internalName}</div>}
                <div className="OrgGuidesModal__item-info-flowId">
                  Frigade ID: {guide.frigadeFlowId} | {guide.displayName}
                </div>
                {guide.description && <div className="OrgGuidesModal__item-info-description">{guide.description}</div>}
              </div>
              <Checkbox
                className="OrgGuidesModal__item-checkbox"
                id={`org-guide-${guide.id}`}
                name="org-guide-checkboxes[]"
                checked={guideIds.includes(guide.id)}
                onChange={() => handleGuideChange(guide.id)}
              />
            </div>
          )))}
      </div>
    </DialogContainer>
  );
};

const mapStateToProps = ({ orgDetails }: ApplicationState) => ({
  loading: orgDetails.assignGuidesListLoading,
  existingGuides: orgDetails.assignExistingGuides,
  guides: orgDetails.assignGuidesList,
});

const mapDispatchToProps = {
  onSearch: assignGuidesSearch,
  onInitializeOrgGuides: assignGuidesInitialize,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgGuidesModal);
