import { GuideListOutput, GuideOutput, OrganizationListOutput, OrganizationOutput } from '@mmc-csm/shared';

export enum GuidesActionTypes {
  LIST = '@@guides/LIST',
  LIST_SUCCESS = '@@guides/LIST_SUCCESS',
  LIST_ERROR = '@@guides/LIST_ERROR',

  SELECT = '@@guides/SELECT',
  UPDATED = '@@guides/UPDATED',

  GET = '@@guides/GET',
  GET_SUCCESS = '@@guides/GET_SUCCESS',
  GET_ERROR = '@@guides/GET_ERROR',

  LIST_ORGS = '@@guides/LIST_ORGS',
  LIST_ORGS_SUCCESS = '@@guides/LIST_ORGS_SUCCESS',
  LIST_ORGS_ERROR = '@@guides/LIST_ORGS_ERROR',

  SEARCH_ORGS = '@@guides/SEARCH_ORGS',
  SEARCH_ORGS_SUCCESS = '@@guides/SEARCH_ORGS_SUCCESS',
  SEARCH_ORGS_ERROR = '@@guides/SEARCH_ORGS_ERROR',

  CREATE_GUIDE = '@@guides/CREATE_GUIDE',
  CREATE_GUIDE_SUCCESS = '@@guides/CREATE_GUIDE_SUCCESS',
  CREATE_GUIDE_ERROR = '@@guides/CREATE_GUIDE_ERROR',

  UPDATE_GUIDE = '@@guides/UPDATE_GUIDE',
  UPDATE_GUIDE_SUCCESS = '@@guides/UPDATE_GUIDE_SUCCESS',
  UPDATE_GUIDE_ERROR = '@@guides/UPDATE_GUIDE_ERROR',

  DELETE_GUIDE = '@@guides/DELETE_GUIDE',
  DELETE_GUIDE_SUCCESS = '@@guides/DELETE_GUIDE_SUCCESS',
  DELETE_GUIDE_ERROR = '@@guides/DELETE_GUIDE_ERROR',
}

export interface GuidesState {
  readonly loading: boolean;
  readonly guideList?: GuideListOutput;
  readonly limit: number;
  readonly offset: number;
  readonly search?: string;
  readonly filter?: string;
  readonly order?: string;
  readonly creatingGuide: boolean;
}

export interface GuideDetailsState {
  readonly guide?: GuideOutput;
  readonly loadingGuide: boolean;
  readonly loadingOrgs: boolean;

  readonly search?: string;
  readonly orgsLimit: number;
  readonly orgsOffset: number;
  readonly orgsList?: OrganizationListOutput;

  // used in GuideOrgs modal
  readonly searchingOrgs: boolean;
  readonly guideSearchOrgs: OrganizationOutput[];
}
