import {
  GuideListOutput, GuideOutput,
  OrganizationGuideListInput,
  OrganizationListInput,
  OrganizationListOutput,
  OrganizationOutput,
  OrganizationUser,
  OrganizationUserListInput,
  OrganizationUserListOutput,
  OrganizationUserRoleListOutput,
  PlanOutput,
  Setting,
  SettingListOutput,
} from '@mmc-csm/shared';
import { action } from 'typesafe-actions';
import { OrgsActionTypes } from './orgs-types';

export const listOrgs = (input?: OrganizationListInput) => action(OrgsActionTypes.LIST, input);
export const listOrgsSuccess = (payload: OrganizationListOutput) => action(OrgsActionTypes.LIST_SUCCESS, payload);
export const listOrgsError = () => action(OrgsActionTypes.LIST_ERROR);

export const selectOrg = (org: OrganizationOutput) => action(OrgsActionTypes.SELECT, { org });
export const updateOrg = (org: OrganizationOutput) => action(OrgsActionTypes.UPDATED, { org });

export const getOrg = (id: number) => action(OrgsActionTypes.GET, { id });
export const getOrgSuccess = (org: OrganizationOutput) => action(OrgsActionTypes.GET_SUCCESS, { org });
export const getOrgError = () => action(OrgsActionTypes.GET_ERROR);

export const listOrgUsers = (input: OrganizationUserListInput) => action(OrgsActionTypes.LIST_USERS, input);
export const listOrgUsersSuccess = (payload: OrganizationUserListOutput) =>
  action(OrgsActionTypes.LIST_USERS_SUCCESS, payload);
export const listOrgUsersError = () => action(OrgsActionTypes.LIST_USERS_ERROR);

export const listOrgGuides = (input: OrganizationGuideListInput) => action(OrgsActionTypes.LIST_GUIDES, input);
export const listOrgGuidesSuccess = (payload: GuideListOutput) =>
  action(OrgsActionTypes.LIST_GUIDES_SUCCESS, payload);
export const listOrgGuidesError = () => action(OrgsActionTypes.LIST_GUIDES_ERROR);

export const listOrgRoles = (id: OrganizationOutput['id']) => action(OrgsActionTypes.LIST_ROLES, id);
export const listOrgRolesSuccess = (payload: OrganizationUserRoleListOutput) =>
  action(OrgsActionTypes.LIST_ROLES_SUCCESS, payload);
export const listOrgRolesError = () => action(OrgsActionTypes.LIST_ROLES_ERROR);

export const listOrgSettings = (id: OrganizationOutput['id']) => action(OrgsActionTypes.LIST_SETTINGS, id);
export const listOrgSettingsSuccess = (payload: SettingListOutput['data']) =>
  action(OrgsActionTypes.LIST_SETTINGS_SUCCESS, payload);
export const listOrgSettingsError = () => action(OrgsActionTypes.LIST_SETTINGS_ERROR);

export const listUserSettings = (orgId: OrganizationOutput['id'], userId: OrganizationUser['id']) =>
  action(OrgsActionTypes.LIST_USER_SETTINGS, { orgId, userId });
export const listUserSettingsSuccess = (payload: SettingListOutput['data']) =>
  action(OrgsActionTypes.LIST_USER_SETTINGS_SUCCESS, payload);
export const listUserSettingsError = () => action(OrgsActionTypes.LIST_USER_SETTINGS_ERROR);

export const changeSeats = (orgId: number, seats: number) =>
  action(OrgsActionTypes.CHANGE_SEATS, { orgId, seats });
export const changeSeatsSuccess = (seats: number) => action(OrgsActionTypes.CHANGE_SEATS_SUCCESS, { seats });
export const changeSeatsError = () => action(OrgsActionTypes.CHANGE_SEATS_ERROR);

export const changeStatus = (orgId: number, isActive: boolean) =>
  action(OrgsActionTypes.CHANGE_STATUS, { orgId, isActive });
export const changeStatusSuccess = (isActive: boolean) => action(OrgsActionTypes.CHANGE_STATUS_SUCCESS, { isActive });
export const changeStatusError = () => action(OrgsActionTypes.CHANGE_STATUS_ERROR);

export const changeFeature = (orgId: number, metaData: OrganizationOutput['metaData'], callback?: () => void) =>
  action(OrgsActionTypes.CHANGE_FEATURE, { orgId, metaData, callback });
export const changeFeatureSuccess = (metaData: OrganizationOutput['metaData']) =>
  action(OrgsActionTypes.CHANGE_FEATURE_SUCCESS, { metaData });
export const changeFeatureError = () => action(OrgsActionTypes.CHANGE_FEATURE_ERROR);

export const changeAddOnServices = (orgId: number, addOnServices: OrganizationOutput['addOnServices']) =>
  action(OrgsActionTypes.CHANGE_ADD_ON_SERVICES, { orgId, addOnServices });
export const changeAddOnServicesSuccess = (addOnServices: OrganizationOutput['addOnServices']) =>
  action(OrgsActionTypes.CHANGE_ADD_ON_SERVICES_SUCCESS, { addOnServices });
export const changeAddOnServicesError = () => action(OrgsActionTypes.CHANGE_ADD_ON_SERVICES_ERROR);

export const changeUserAddOnServices = (orgId: number, user: OrganizationUser) =>
  action(OrgsActionTypes.CHANGE_USER_ADD_ON_SERVICES, { orgId, user });
export const changeUserAddOnServicesSuccess = (user: OrganizationUser) =>
  action(OrgsActionTypes.CHANGE_USER_ADD_ON_SERVICES_SUCCESS, user);
export const changeUserAddOnServicesError = () =>
  action(OrgsActionTypes.CHANGE_USER_ADD_ON_SERVICES_ERROR);

export const changeName = (orgId: number, name: string) =>
  action(OrgsActionTypes.CHANGE_NAME, { orgId, name });
export const changeNameSuccess = (name: string) => action(OrgsActionTypes.CHANGE_NAME_SUCCESS, { name });

export const changeRole = (orgId: number, id: number, username: string, role: OrganizationUser['role']) =>
  action(OrgsActionTypes.CHANGE_ROLE, { orgId, id, username, role });
export const changeRoleSuccess = (roleId: number) => action(OrgsActionTypes.CHANGE_ROLE_SUCCESS, { roleId });

export const changeSetting = (orgId: OrganizationOutput['id'], setting: Setting) =>
  action(OrgsActionTypes.CHANGE_SETTING, { orgId, setting });
export const changeSettingSuccess = (setting: Setting) =>
  action(OrgsActionTypes.CHANGE_SETTING_SUCCESS, { setting });

export const changeUserSetting = (orgId: OrganizationOutput['id'], userId: OrganizationUser['id'], setting: Setting) =>
  action(OrgsActionTypes.CHANGE_SETTING, { orgId, userId, setting });
export const changeUserSettingSuccess = (setting: Setting) =>
  action(OrgsActionTypes.CHANGE_USER_SETTING_SUCCESS, { setting });

export const resetPasswordCode = (userId: number, username: string) =>
  action(OrgsActionTypes.RESET_PASSWORD_CODE, { userId, username });
export const resetPasswordCodeSuccess = (code: string) => action(OrgsActionTypes.RESET_PASSWORD_CODE_SUCCESS, { code });

export const extendTrial = (orgId: number, trialExpiresAt: string) =>
  action(OrgsActionTypes.EXTEND_TRIAL, { orgId, trialExpiresAt });
export const extendTrialSuccess = (trialExpiresAt: string) =>
  action(OrgsActionTypes.EXTEND_TRIAL_SUCCESS, { trialExpiresAt });

export const setStripeId = (orgId: number, stripeId: string) =>
  action(OrgsActionTypes.SET_STRIPE_ID, { orgId, stripeId });
export const setStripeIdSuccess = (stripeId: string) =>
  action(OrgsActionTypes.SET_STRIPE_ID_SUCCESS, { stripeId });

export const setSubscriptionId = (orgId: number, subscriptionId: string) =>
  action(OrgsActionTypes.SET_SUBSCRIPTION_ID, { orgId, subscriptionId });
export const setSubscriptionIdSuccess = (subscriptionId: string) =>
  action(OrgsActionTypes.SET_SUBSCRIPTION_ID_SUCCESS, { subscriptionId });

export const extendTrialError = () => action(OrgsActionTypes.EXTEND_TRIAL_ERROR);

export const upgradePlan = (orgId: number, planId: number) => action(OrgsActionTypes.UPGRADE_PLAN, { orgId, planId });
export const upgradePlanSuccess = (plan: PlanOutput) => action(OrgsActionTypes.UPGRADE_PLAN_SUCCESS, { plan });
export const upgradePlanError = () => action(OrgsActionTypes.UPGRADE_PLAN_ERROR);

export const assignGuidesInitialize = (orgId: number) => action(OrgsActionTypes.ASSIGN_GUIDES_INITIALIZE, { orgId });
export const assignGuidesInitializeSuccess = (guides: GuideOutput[]) => action(OrgsActionTypes.ASSIGN_GUIDES_INITIALIZE_SUCCESS, { guides });
export const assignGuidesInitializeError = () => action(OrgsActionTypes.ASSIGN_GUIDES_INITIALIZE_ERROR);

export const assignGuidesSearch = (orgId: number, search: string) => action(OrgsActionTypes.ASSIGN_GUIDES_SEARCH, { orgId, search });
export const assignGuidesSearchSuccess = (guides: GuideOutput[]) => action(OrgsActionTypes.ASSIGN_GUIDES_SEARCH_SUCCESS, { guides });
export const assignGuidesSearchError = () => action(OrgsActionTypes.ASSIGN_GUIDES_SEARCH_ERROR);

export const assignGuidesUpdate = (orgId: number, guideIds: GuideOutput['id'][], callback?: () => void) => action(OrgsActionTypes.ASSIGN_GUIDES_UPDATE, { orgId, guideIds, callback });
export const assignGuidesUpdateSuccess = () => action(OrgsActionTypes.ASSIGN_GUIDES_UPDATE_SUCCESS);
export const assignGuidesUpdateError = () => action(OrgsActionTypes.ASSIGN_GUIDES_UPDATE_ERROR);
