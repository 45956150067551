import React, { useState } from 'react';
import { DataTable, FontIcon, TableBody, TableColumn, TableHeader, TablePagination, TableRow } from 'react-md';
import { GuideListOutput, GuideOutput } from '@mmc-csm/shared';

type GuidesDataTableProps = {
  guideList?: GuideListOutput,
  id: string,
  onPagination: (start: number, rowsPerPage: number) => void;
  onGuideClicked?: (guide: GuideOutput) => void;
  onHeaderClicked?: (column: string) => void;
};

const rowsPerPageItems = [10, 15, 30, 50];

export const GuidesDataTable: React.FC<GuidesDataTableProps> = props => {
  const defaultList = {
    data: undefined,
    total: 0,
    limit: 10,
    offset: 0,
  };

  const { guideList = defaultList, onPagination, onGuideClicked, onHeaderClicked } = props;
  const guides = guideList.data || [];
  const rowsCount = guideList.total || 0;
  const rowsPerPage = guideList.limit || defaultList.limit;
  const offset = guideList.offset || 0;
  const page = offset / rowsPerPage + 1;

  const [sortedColumn, setSortedColumn] = useState('');
  const [direction, setDirection] = useState(true);
  const [columnIndex, setColumnIndex] = useState(0);

  const SortingArrow = ({ direction = true }) => {
    if (direction) return <FontIcon>arrow_drop_up</FontIcon>;
    if (direction === false) return <FontIcon>arrow_drop_down</FontIcon>;
    return <FontIcon />;
  };

  function sortingOrder(columnval:number, column:string) {
    if (column !== sortedColumn || columnIndex === 0) {
      setSortedColumn(column);
      columnval = 0;
    }

    switch (columnval) {
      case 0:
        setColumnIndex(1);
        setDirection(false);
        return column;
      case 1:
        setColumnIndex(2);
        setDirection(true);
        return `-${column}`;
      default:
        setColumnIndex(0);
        setSortedColumn('');
        return '';
    }
  }

  const formatDate = (string = '') => {
    return string != null ? `${`${string.substring(5, 7)}/${string.substring(8, 10)}/${string.substring(0, 4)}`}` : string;
  };

  return (
    <DataTable baseId={props.id} plain responsive={false} className="GuidesDataTable">
      <TableHeader>
        <TableRow>
          <TableColumn
            className="ColumnFrigadeId"
            sorted={sortedColumn === 'frigadeFlowId' ? direction : undefined}
            sortIconBefore={false}
            sortIcon={<SortingArrow direction={sortedColumn === 'frigadeFlowId' ? direction : undefined} />}
            onClick={() => (onHeaderClicked ? onHeaderClicked(sortingOrder(columnIndex, 'frigadeFlowId')) : undefined)}
          >
              Frigade ID
          </TableColumn>
          <TableColumn
            className="ColumnGuideType"
          >
            Type
          </TableColumn>
          <TableColumn
            className="ColumnGuideInnerName"
            sorted={sortedColumn === 'internalName' ? direction : undefined}
            sortIconBefore={false}
            sortIcon={<SortingArrow direction={sortedColumn === 'internalName' ? direction : undefined} />}
            onClick={() => (onHeaderClicked ? onHeaderClicked(sortingOrder(columnIndex, 'internalName')) : undefined)}
          >
              Name
          </TableColumn>
          <TableColumn
            className="ColumnGuideDisplayName"
            sorted={sortedColumn === 'displayName' ? direction : undefined}
            sortIconBefore={false}
            sortIcon={<SortingArrow direction={sortedColumn === 'displayName' ? direction : undefined} />}
            onClick={() => (onHeaderClicked ? onHeaderClicked(sortingOrder(columnIndex, 'displayName')) : undefined)}
          >
              Name in UI
          </TableColumn>
          <TableColumn
            className="ColumnGuideActiveOrgs"
          >
              Active Orgs
          </TableColumn>
          <TableColumn
            className="ColumnGuideUpdatedAt"
            sorted={sortedColumn === 'updatedAt' ? direction : undefined}
            sortIconBefore={false}
            sortIcon={<SortingArrow direction={sortedColumn === 'updatedAt' ? direction : undefined} />}
            onClick={() => (onHeaderClicked ? onHeaderClicked(sortingOrder(columnIndex, 'updatedAt')) : undefined)}
          >
            Modified At
          </TableColumn>
        </TableRow>
      </TableHeader>

      <TableBody>
        {guides.map(guide => (
          <TableRow key={guide.id} onClick={() => (onGuideClicked ? onGuideClicked(guide) : undefined)}>
            <TableColumn className="ColumnFrigadeId">{guide.frigadeFlowId}</TableColumn>
            <TableColumn className="ColumnGuideType">{guide.type}</TableColumn>
            <TableColumn className="ColumnGuideInnerName">{guide.internalName}</TableColumn>
            <TableColumn className="ColumnGuideDisplayName">{guide.displayName}</TableColumn>
            <TableColumn className="ColumnGuideActiveOrgs">{guide.orgIds ? guide.orgIds.length : 0}</TableColumn>
            <TableColumn className="ColumnGuideUpdatedAt">{formatDate(guide.updatedAt)}</TableColumn>
          </TableRow>
        ))}
      </TableBody>
      {!rowsCount && (
      <p className='NoResults'>
No Results Found.
        <br />
Clear Filters or Search Again
      </p>
      )}
      <TablePagination
        rows={rowsCount}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageItems={rowsPerPageItems}
        onPagination={onPagination}
      />

    </DataTable>
  );
};
