import React, { useState } from 'react';
import { Button, FontIcon, TextField } from 'react-md';

interface OrgGuidesSearchProps {
  defaultValue?: string;
  onSearch: (search: string) => void;
  onAddClick: () => void;
  onAssignClick: () => void;
}

export const OrgGuidesSearchBar: React.FC<OrgGuidesSearchProps> = props => {
  const [buttonState, setButtonState] = useState(false);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      props.onSearch((e.currentTarget as HTMLInputElement).value);
      setButtonState(true);
    }
  };

  const handleClick = () => {
    const fieldInput = document.getElementById('orgs-guides-search') as HTMLInputElement | null;

    if (!buttonState && fieldInput) {
      props.onSearch(fieldInput.value);
      setButtonState(true);
    } else {
      (document.getElementById('orgs-guides-search') as HTMLInputElement).value = '';
      props.onSearch('');
      setButtonState(false);
    }
  };
  const buttonIcon = () => {
    if (buttonState) return <FontIcon>close</FontIcon>;
    return <FontIcon>search</FontIcon>;
  };

  return (

    <div className="OrgSearchBar">
      <div>
        <h3 className="OrgDetailsSubtitle">
          Guides
          &nbsp;
          &nbsp;
          <Button primary flat onClick={props.onAssignClick}>Assign</Button>
          &nbsp;
          <Button primary flat onClick={props.onAddClick}>Add New</Button>
        </h3>
      </div>
      <div />
      <div className="OrgSearchInput">
        <TextField
          id="orgs-guides-search"
          defaultValue={props.defaultValue}
          inlineIndicator={<Button onClick={handleClick}>{buttonIcon()}</Button>}
          onKeyDown={handleKeyDown}
          fullWidth
          placeholder="Search by Name or Flow Id"
        />
      </div>
    </div>
  );
};
