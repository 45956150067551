import React, { ReactText, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { Button, Cell, CircularProgress, Grid, TextField } from 'react-md';
import { OrganizationOutput } from '@mmc-csm/shared';
import { ApplicationState } from '../../store';
import { GuideDetailsState } from '../../store/guides/guides-types';
import { deleteGuide, getGuide, listGuideOrgs, updateGuide } from '../../store/guides/guides-actions';
import { DataTableSpinner } from '../utils/DataTableSpinner';
import GuideOrgsDataTable from './GuideOrgsDataTable';
import { GuideOrgsSearchBar } from './GuideOrgsSearchBar';
import GuideOrgsModal from './GuideOrgsModal';
import GuideDeleteConfirmationModal from './GuideDeleteConfirmationModal';

interface PropsFromActions {
  onGetGuide: typeof getGuide;
  onListGuideOrgs: typeof listGuideOrgs;
  onUpdateGuide: typeof updateGuide;
  onDeleteGuide: typeof deleteGuide;
  push: typeof push;
}

type RouteProps = RouteComponentProps<{ id: string }>;
type GuideDetailsProps = PropsFromActions & GuideDetailsState & RouteProps;

const GuideDetails: React.FC<GuideDetailsProps> = ({ guide, onUpdateGuide, onDeleteGuide, orgsOffset, orgsLimit, onListGuideOrgs, push, orgsList, loadingGuide, loadingOrgs, match, onGetGuide }) => {
  const [name, setName] = useState(guide ? guide.internalName : '');
  const [displayName, setDisplayName] = useState(guide ? guide.displayName : '');
  const [description, setDescription] = useState(guide ? guide.description : '');

  const [offset, setOffset] = useState(orgsOffset);
  const [limit, setLimit] = useState(orgsLimit);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const id = parseInt(match.params.id, 10);
    if (!Number.isInteger(id)) {
      push('/adm/guides');
      return;
    }

    if (!guide || guide.id !== id) {
      onGetGuide(id);
    }
  }, [push, match, onGetGuide, guide]);

  useEffect(() => {
    if (guide) {
      setName(guide.internalName);
      setDisplayName(guide.displayName);
      setDescription(guide.description);
    }
  }, [guide, onListGuideOrgs]);

  useEffect(() => {
    if (guide) {
      onListGuideOrgs({
        orgIds: guide.orgIds || [],
        limit,
        offset,
        search: search
          ? `{"$or":[{"name":{"$in":"${search}"}}]}`
          : '',
      });
    }
  }, [onListGuideOrgs, guide, offset, limit, search]);

  const handleChangeName = (value: ReactText) => setName(value as string);
  const handleClearName = () => setName(guide!.internalName);
  const handleSaveName = () => onUpdateGuide({ ...guide!, internalName: name });

  const handleChangeDisplayName = (value: ReactText) => setDisplayName(value as string);
  const handleClearDisplayName = () => setDisplayName(guide!.displayName);
  const handleSaveDisplayName = () => onUpdateGuide({ ...guide!, displayName });

  const handleChangeDescription = (value: ReactText) => setDescription(value as string);
  const handleClearDescription = () => setDescription(guide!.description);
  const handleSaveDescription = () => onUpdateGuide({ ...guide!, description });

  const handleOrgsPagination = (start: number, rowsPerPage: number) => {
    setOffset(start);
    setLimit(rowsPerPage);
  };

  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const handleAssignClick = () => setAssignModalVisible(true);

  const handleSave = useCallback((orgIds: OrganizationOutput['id'][]) => {
    onUpdateGuide({ ...guide!, orgIds }, () => setAssignModalVisible(false));
  }, [guide, onUpdateGuide]);

  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const handleDelete = useCallback(() => {
    onDeleteGuide(guide!.id);
  }, [onDeleteGuide, guide]);

  if (loadingGuide || !guide) {
    return <CircularProgress id="loading-org-prg" />;
  }

  return (
    <div className="GuideDetails">
      <section>
        <Grid>
          <Cell size={3}>
            <TextField
              id="guide-details-name"
              className="GuideName"
              label="Name"
              inputStyle={{ fontSize: 22 }}
              value={name}
              onChange={handleChangeName}
            />
          </Cell>
          {guide.internalName !== name ? (
            <Cell size={2}>
              <Button primary flat onClick={handleSaveName}>
                  Save
              </Button>
              <Button flat onClick={handleClearName}>
                  Cancel
              </Button>
            </Cell>
          ) : <Cell size={2} />}
          <Cell offset={6} size={1}>
            <Button flat secondary onClick={() => setDeleteVisible(true)}>Delete</Button>
            {deleteVisible && (
              <GuideDeleteConfirmationModal
                guideName={guide.internalName}
                onDelete={handleDelete}
                onCancel={() => setDeleteVisible(false)}
              />
            )}
          </Cell>
        </Grid>

        <Grid>
          <Cell size={3}>
            <TextField
              id="guide-details-display-name"
              className="GuideName"
              label="Name in UI"
              value={displayName}
              onChange={handleChangeDisplayName}
            />
          </Cell>
          {guide.displayName !== displayName ? (
            <Cell size={2}>
              <Button primary flat onClick={handleSaveDisplayName}>
              Save
              </Button>
              <Button flat onClick={handleClearDisplayName}>
              Cancel
              </Button>
            </Cell>
          ) : <Cell size={2} />}
          <Cell offset={5} size={2} />
        </Grid>

        <Grid>
          <Cell size={3}>
            <TextField
              id="guide-details-description"
              className="GuideName"
              label="Description in UI"
              value={description}
              maxLength={200}
              onChange={handleChangeDescription}
            />
          </Cell>
          {guide.description !== description ? (
            <Cell size={2}>
              <Button primary flat onClick={handleSaveDescription}>
                Save
              </Button>
              <Button flat onClick={handleClearDescription}>
                Cancel
              </Button>
            </Cell>
          ) : <Cell size={2} />}
          <Cell offset={5} size={2} />
        </Grid>

        <Grid>
          <Cell size={1}>
            <TextField id="guide-details-frigade-id" className="GuideID" label="Frigade ID" disabled defaultValue={guide.frigadeFlowId} />
          </Cell>
          <Cell size={1} offset={1}>
            <TextField id="guide-details-id" className="GuideID" label="Guide ID" disabled defaultValue={guide.id} />
          </Cell>
          <Cell size={3} />
        </Grid>
        <Grid>
          <Cell size={3} />
        </Grid>
        <Grid>
          <Cell size={3} />
        </Grid>
        <Grid>
          <Cell size={3} />
        </Grid>
      </section>
      <section>
        <div>
          <GuideOrgsSearchBar defaultValue={search} onSearch={setSearch} onAssignClick={handleAssignClick} />
        </div>
        <DataTableSpinner id="guide-orgs-dt-spinner" loading={loadingOrgs}>
          <GuideOrgsDataTable
            id="guide-orgs-dt-table"
            onPagination={handleOrgsPagination}
            guide={guide}
            orgsList={orgsList}
          />
        </DataTableSpinner>
      </section>
      {assignModalVisible && (
        <GuideOrgsModal
          orgIds={guide.orgIds || []}
          onClose={() => setAssignModalVisible(false)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ guideDetails }: ApplicationState) => ({
  ...guideDetails,
});

const mapDispatchToProps = {
  onGetGuide: getGuide,
  push,
  onListGuideOrgs: listGuideOrgs,
  onUpdateGuide: updateGuide,
  onDeleteGuide: deleteGuide,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(GuideDetails));
