import React, { FC, ReactText, useCallback, useState } from 'react';
import { Button, Drawer, SelectField, TextField, Toolbar } from 'react-md';
import { GuideOutput, OrganizationOutput } from '@mmc-csm/shared';
import { connect } from 'react-redux';
import { createGuide, updateGuide, deleteGuide } from '../../store/guides/guides-actions';
import GuideDeleteConfirmationModal from './GuideDeleteConfirmationModal';
import GuideRemoveConfirmationModal from './GuideRemoveConfirmationModal';

const GUIDE_TYPES = [{ label: 'Web', value: 'web' }, { label: 'Mobile', value: 'mobile' }];

export interface NewGuideDrawerProps {
  guide?: GuideOutput;
  orgId?: OrganizationOutput['id'], // when opening from the org view
  orgIds?: OrganizationOutput['id'][],
  onClose: () => void,
  onCreateGuide: typeof createGuide,
  onUpdateGuide: typeof updateGuide,
  onDeleteGuide: typeof deleteGuide,
  openGuide?: boolean,
  onCreated?: (guide: GuideOutput) => void,
  onDeleted?: () => void,
}

const CreateEditGuideDrawer: FC<NewGuideDrawerProps> = ({ guide, onClose, onCreated, orgId, onDeleted, openGuide = true, onUpdateGuide, onCreateGuide, onDeleteGuide, orgIds }) => {
  const handleVisibilityChange = (visible: boolean) => {
    if (!visible) {
      onClose();
    }
  };

  const [flowId, setFlowId] = useState<ReactText>(guide ? guide.frigadeFlowId || '' : '');
  const [name, setName] = useState<ReactText>(guide ? guide.internalName || '' : '');
  const [displayName, setDisplayName] = useState<ReactText>(guide ? guide.displayName || '' : '');
  const [description, setDescription] = useState<ReactText>(guide ? guide.description || '' : '');
  const [type, setType] = useState<string>(guide ? guide.type || 'web' : 'web');

  const saveEnabled = (flowId as string).trim().length > 0 && (name as string).trim().length > 0 && (displayName as string).trim().length > 0;

  const handleSave = () => {
    if (guide) {
      onUpdateGuide({ ...guide, internalName: name as string, displayName: displayName as string, description: description as string });
      onClose();
    } else {
      onCreateGuide(
        {
          frigadeFlowId: flowId as string,
          internalName: name as string,
          displayName: displayName as string,
          description: description as string,
          type,
          orgIds: orgIds || [],
        },
        openGuide,
        (guide: GuideOutput) => {
          onClose();
          if (onCreated) {
            onCreated(guide);
          }
        },
      );
    }
  };

  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const handleRemove = useCallback(() => {
    if (guide) {
      onUpdateGuide(
        { ...guide, orgIds: guide.orgIds!.filter(id => id !== orgId) },
        () => {
          if (onDeleted) {
            onDeleted();
          }
          onClose();
        },
      );
    }
  }, [guide, orgId, onUpdateGuide, onClose, onDeleted]);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDelete = useCallback(() => {
    if (guide) {
      onDeleteGuide(guide.id, onDeleted);
      onClose();
    }
  }, [guide, onDeleteGuide, onClose, onDeleted]);

  console.log("!!! guide && orgId && guide.orgIds && guide.orgIds.includes(orgId)=", guide && orgId && guide.orgIds && guide.orgIds.includes(orgId), guide, orgId);

  return (
    <Drawer
      id="create-edit-guide-drawer"
      className="CreateEditGuideDrawer"
      type={Drawer.DrawerTypes.TEMPORARY}
      visible
      position="right"
      onVisibilityChange={handleVisibilityChange}
      header={(
        <Toolbar
          actions={(
            <div className="CreateEditGuideDrawer__buttons">
              <Button flat onClick={onClose}>Cancel</Button>
              <Button flat primary disabled={!saveEnabled} onClick={handleSave}>Save</Button>
            </div>
          )}
          className="md-divider-border md-divider-border--bottom"
          title={guide ? 'Guide' : 'Add Guide'}
        />
      )}
      style={{ zIndex: 999, width: 480 }}
    >
      <div className="CreateEditGuideDrawer__form">
        <TextField id="create-edit-guide-frigade-id" label="Frigade ID" disabled={!!guide} onChange={setFlowId} value={flowId} />
        {guide && (
          <TextField id="create-edit-guide-guide-id" label="Guide ID" disabled defaultValue={guide!.id} />
        )}
        <TextField id="create-edit-guide-name" label="Name" onChange={setName} value={name} />
        <TextField id="create-edit-guide-display-name" label="Name in UI" onChange={setDisplayName} value={displayName} />
        <TextField id="create-edit-guide-description" label="Description in UI" onChange={setDescription} value={description} maxLength={200} />
        <SelectField
          disabled={!!guide}
          id="create-edit-guide-type"
          label="Type"
          menuItems={GUIDE_TYPES}
          simplifiedMenu
          onChange={(value: string | number) => setType(value as string)}
          value={type}
        />
        <div className="CreateEditGuideDrawer__spacer" />
        {guide && orgId && guide.orgIds && guide.orgIds.includes(orgId) && (
          <Button secondary flat onClick={() => setRemoveConfirmation(true)}>Remove from Org</Button>
        )}
        {guide && <Button secondary flat onClick={() => setDeleteConfirmation(true)}>Delete</Button>}
        {removeConfirmation && (
          <GuideRemoveConfirmationModal
            guideName={guide!.internalName || ''}
            onCancel={() => setRemoveConfirmation(false)}
            onRemove={handleRemove}
          />
        )}
        {deleteConfirmation && (
          <GuideDeleteConfirmationModal
            guideName={guide!.internalName || ''}
            onCancel={() => setDeleteConfirmation(false)}
            onDelete={handleDelete}
          />
        )}
      </div>
    </Drawer>
  );
};

const mapDispatchToProps = {
  onCreateGuide: createGuide,
  onUpdateGuide: updateGuide,
  onDeleteGuide: deleteGuide,
};

export default connect(
  null,
  mapDispatchToProps,
)(CreateEditGuideDrawer);
