import {
  GuideListOutput, GuideOutput,
  OrganizationListOutput,
  OrganizationOutput,
  OrganizationUser,
  OrganizationUserListOutput,
  OrganizationUserRoleListOutput, SettingListOutput
} from '@mmc-csm/shared';

export enum OrgsActionTypes {
  LIST = '@@orgs/LIST',
  LIST_SUCCESS = '@@orgs/LIST_SUCCESS',
  LIST_ERROR = '@@orgs/LIST_ERROR',

  SELECT = '@@orgs/SELECT',
  UPDATED = '@@orgs/UPDATED',

  GET = '@@orgs/GET',
  GET_SUCCESS = '@@orgs/GET_SUCCESS',
  GET_ERROR = '@@orgs/GET_ERROR',

  LIST_USERS = '@@orgs/LIST_USERS',
  LIST_USERS_SUCCESS = '@@orgs/LIST_USERS_SUCCESS',
  LIST_USERS_ERROR = '@@orgs/LIST_USERS_ERROR',

  LIST_GUIDES = '@@orgs/LIST_GUIDES',
  LIST_GUIDES_SUCCESS = '@@orgs/LIST_GUIDES_SUCCESS',
  LIST_GUIDES_ERROR = '@@orgs/LIST_GUIDES_ERROR',

  LIST_ROLES = '@@orgs/LIST_ROLES',
  LIST_ROLES_SUCCESS = '@@orgs/LIST_ROLES_SUCCESS',
  LIST_ROLES_ERROR = '@@orgs/LIST_ROLES_ERROR',

  LIST_SETTINGS = '@@orgs/LIST_SETTINS',
  LIST_SETTINGS_SUCCESS = '@@orgs/LIST_SETTINGS_SUCCESS',
  LIST_SETTINGS_ERROR = '@@orgs/LIST_SETTINGS_ERROR',

  LIST_USER_SETTINGS = '@@orgs/LIST_USER_SETTINS',
  LIST_USER_SETTINGS_SUCCESS = '@@orgs/LIST_USER_SETTINGS_SUCCESS',
  LIST_USER_SETTINGS_ERROR = '@@orgs/LIST_USER_SETTINGS_ERROR',

  CHANGE_SEATS = '@@orgs/CHANGE_SEATS',
  CHANGE_SEATS_SUCCESS = '@@orgs/CHANGE_SEATS_SUCCESS',
  CHANGE_SEATS_ERROR = '@@orgs/CHANGE_SEATS_ERROR',

  CHANGE_STATUS = '@@orgs/CHANGE_STATUS',
  CHANGE_STATUS_SUCCESS = '@@orgs/CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_ERROR = '@@orgs/CHANGE_STATUS_ERROR',

  CHANGE_FEATURE = '@@orgs/FEATURE',
  CHANGE_FEATURE_SUCCESS = '@@orgs/FEATURE_SUCCESS',
  CHANGE_FEATURE_ERROR = '@@orgs/FEATURE_ERROR',

  CHANGE_ADD_ON_SERVICES = '@@orgs/ADD_ON_SERVICES',
  CHANGE_ADD_ON_SERVICES_SUCCESS = '@@orgs/ADD_ON_SERVICES_SUCCESS',
  CHANGE_ADD_ON_SERVICES_ERROR = '@@orgs/ADD_ON_SERVICES_ERROR',

  CHANGE_USER_ADD_ON_SERVICES = '@@orgs/USER_ADD_ON_SERVICES',
  CHANGE_USER_ADD_ON_SERVICES_SUCCESS = '@@orgs/USER_ADD_ON_SERVICES_SUCCESS',
  CHANGE_USER_ADD_ON_SERVICES_ERROR = '@@orgs/USER_ADD_ON_SERVICES_ERROR',

  CHANGE_NAME = '@@orgs/CHANGE_NAME',
  CHANGE_NAME_SUCCESS = '@@orgs/CHANGE_NAME_SUCCESS',
  CHANGE_NAME_ERROR = '@@orgs/CHANGE_NAME_ERROR',

  CHANGE_ROLE = '@@orgs/CHANGE_ROLE',
  CHANGE_ROLE_SUCCESS = '@@orgs/CHANGE_ROLE_SUCCESS',
  CHANGE_ROLE_ERROR = '@@orgs/CHANGE_ROLE_ERROR',

  CHANGE_SETTING = '@@orgs/CHANGE_SETTING',
  CHANGE_SETTING_SUCCESS = '@@orgs/CHANGE_SETTING_SUCCESS',
  CHANGE_SETTING_ERROR = '@@orgs/CHANGE_SETTING_ERROR',

  CHANGE_USER_SETTING = '@@orgs/CHANGE_USER_SETTING',
  CHANGE_USER_SETTING_SUCCESS = '@@orgs/CHANGE_USER_SETTING_SUCCESS',
  CHANGE_USER_SETTING_ERROR = '@@orgs/CHANGE_USER_SETTING_ERROR',

  RESET_PASSWORD_CODE = '@@orgs/RESET_PASSWORD_CODE',
  RESET_PASSWORD_CODE_SUCCESS = '@@orgs/RESET_PASSWORD_CODE_SUCCESS',
  RESET_PASSWORD_CODE_ERROR = '@@orgs/RESET_PASSWORD_CODE_ERROR',

  EXTEND_TRIAL = '@@orgs/EXTEND_TRIAL',
  EXTEND_TRIAL_SUCCESS = '@@orgs/EXTEND_TRIAL_SUCCESS',
  EXTEND_TRIAL_ERROR = '@@orgs/EXTEND_TRIAL_ERROR',

  UPGRADE_PLAN = '@@orgs/UPGRADE_PLAN',
  UPGRADE_PLAN_SUCCESS = '@@orgs/UPGRADE_PLAN_SUCCESS',
  UPGRADE_PLAN_ERROR = '@@orgs/UPGRADE_PLAN_ERROR',

  SET_STRIPE_ID = '@@orgs/SET_STRIPE_ID',
  SET_STRIPE_ID_SUCCESS = '@@orgs/SET_STRIPE_ID_SUCCESS',
  SET_STRIPE_ID_ERROR = '@@orgs/SET_STRIPE_ID_ERROR',

  SET_SUBSCRIPTION_ID = '@@orgs/SET_SUBSCRIPTION_ID',
  SET_SUBSCRIPTION_ID_SUCCESS = '@@orgs/SET_SUBSCRIPTION_ID_SUCCESS',
  SET_SUBSCRIPTION_ID_ERROR = '@@orgs/SET_SUBSCRIPTION_ID_ERROR',

  ASSIGN_GUIDES_INITIALIZE = '@@orgs/ASSIGN_GUIDES_INITIALIZE',
  ASSIGN_GUIDES_INITIALIZE_SUCCESS = '@@orgs/ASSIGN_GUIDES_INITIALIZE_SUCCESS',
  ASSIGN_GUIDES_INITIALIZE_ERROR = '@@orgs/ASSIGN_GUIDES_INITIALIZE_ERROR',

  ASSIGN_GUIDES_SEARCH = '@@orgs/ASSIGN_GUIDES_SEARCH',
  ASSIGN_GUIDES_SEARCH_SUCCESS = '@@orgs/ASSIGN_GUIDES_SEARCH_SUCCESS',
  ASSIGN_GUIDES_SEARCH_ERROR = '@@orgs/ASSIGN_GUIDES_SEARCH_ERROR',

  ASSIGN_GUIDES_UPDATE = '@@orgs/ASSIGN_GUIDES_UPDATE',
  ASSIGN_GUIDES_UPDATE_SUCCESS = '@@orgs/ASSIGN_GUIDES_UPDATE_SUCCESS',
  ASSIGN_GUIDES_UPDATE_ERROR = '@@orgs/ASSIGN_GUIDES_UPDATE_ERROR',
}

export interface OrgsState {
  readonly loading: boolean;
  readonly orgsList?: OrganizationListOutput;
  readonly limit: number;
  readonly offset: number;
  readonly search?: string;
  readonly filter?: string;
  readonly order?: string;
  readonly passwordCodeGenerating?: boolean;
  readonly resetPasswordCode?: string;
}

export interface OrgDetailsState {
  readonly org?: OrganizationOutput;
  readonly user?: OrganizationUser;
  readonly loadingOrg: boolean;
  readonly loadingUsers: boolean;
  readonly loadingUserSettings: boolean;

  readonly usersLimit: number;
  readonly usersOffset: number;
  readonly usersList?: OrganizationUserListOutput;
  readonly rolesList?: OrganizationUserRoleListOutput;
  readonly settingsList: SettingListOutput['data'],
  readonly userSettingsList: SettingListOutput['data'],

  readonly search?: string;

  readonly loadingGuides: boolean;
  readonly guidesSearch?: string;
  readonly guidesLimit: number;
  readonly guidesOffset: number;
  readonly guidesList?: GuideListOutput;

  // used in OrgGuides modal
  readonly assignSearch: string;
  readonly assignExistingGuides: GuideOutput[];
  readonly assignGuidesListLoading: boolean,
  readonly assignGuidesList: GuideOutput[];

  readonly changingSeats: boolean;
  readonly changingStatus: boolean;
  readonly changingName: boolean;
  readonly changingRole: boolean;
  readonly changingSetting: boolean;
  readonly changingUserSetting: boolean;
  readonly changingFeature: boolean,
  readonly changingAddOnServices: boolean,
  readonly changingUserAddOnServices: boolean,
  readonly extendingTrial: boolean;
  readonly upgradingPlan: boolean;
}
