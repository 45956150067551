import React, { ReactText, useCallback, useState } from 'react';
import { Button, Divider, SelectField } from 'react-md';
import { OrganizationUser, OrganizationUserRole } from '@mmc-csm/shared';
import TextField from 'react-md/lib/TextFields/TextField';
import { FeatureToggle } from './OrgFeatures';

interface Props {
  onResetPassword: () => void,
  roles: OrganizationUserRole[],
  onHide: () => void,
  onChange: (roleId: OrganizationUserRole['id'], addOns: Partial<OrganizationUser['addOns']>) => void,
  user: OrganizationUser,
}

const OrgUserItems = ({ onChange, onHide, roles, onResetPassword, user }: Props) => {
  const [selectedRoleId, setSelectedRoleId] = useState<number>(user.role.id);

  const [leadFinderLimit, setLeadFinderLimit] = useState<number | string>(user.addOns.googleLeadGenCap ? user.addOns.googleLeadGenCap : 0);
  const isValidLeadFinderLimit = Number.isInteger(parseInt(`${leadFinderLimit}`.trim(), 10)) && leadFinderLimit >= 0;
  const [leadFinderInputError, setLeadFinderInputError] = useState<string>('');
  const handleChangeLimit = useCallback(
    (value: ReactText) => {
      const parsedValue = parseInt(`${value}`.trim(), 10);
      if (!Number.isNaN(parsedValue) && parsedValue > 0) {
        setLeadFinderInputError('');
      } else if (Number.isNaN(parsedValue) && `${value}`.trim() !== '') {
        setLeadFinderInputError('Please enter a number');
      } else if (parsedValue < 0) {
        setLeadFinderInputError('Limit must be positive, enter 0 for no limit');
      }
      setLeadFinderLimit(Number.isNaN(parsedValue) ? '' : parsedValue);
    },
    [],
  );

  const [emailService, setEmailService] = useState(user.addOns.emailService ? FeatureToggle.ON : FeatureToggle.OFF);
  const [mileageTracking, setMileageTracking] = useState(user.addOns.mileageTracking ? FeatureToggle.ON : FeatureToggle.OFF);
  const [activeCheckIn, setActiveCheckIn] = useState(user.addOns.activeCheckIn === false ? FeatureToggle.OFF : FeatureToggle.ON);

  const saveInput = useCallback(() => {
    user.role.name = roles.find(({ id }) => id === selectedRoleId)!.name;
    onChange(selectedRoleId,
      {
        activeCheckIn: activeCheckIn === FeatureToggle.ON,
        emailService: emailService === FeatureToggle.ON,
        googleLeadGenCap: leadFinderLimit as number,
        mileageTracking: mileageTracking === FeatureToggle.ON,
      });
  },
  // It warns about adding user.role.name to deps, which doesn't seem correct to me.
  // Direct modification of user.role.name doesn't seem correct either, but that's a separate concern.
  // eslint-disable-next-line
  [activeCheckIn, roles, selectedRoleId, onChange, leadFinderLimit, emailService, mileageTracking]);

  const saveButtonDisabled = !isValidLeadFinderLimit;

  return (
    <div className="orgUserItemsDrawerContainer">
      <div className="orgUserItemsDrawerHeader">
        <h2>{user.fullName}</h2>
        <div>
          <Button flat onClick={() => onHide()}>
            Cancel
          </Button>

          <Button
            disabled={saveButtonDisabled}
            flat
            onClick={saveInput}
            primary
          >
            Save
          </Button>
        </div>
      </div>

      <Divider className="divider" />
      <div>
        <Button
          primary
          onClick={onResetPassword}
          flat
        >
          Reset Password
        </Button>
      </div>
      <div>
        <SelectField
          id="roleType"
          className="roleSelect"
          label="Role"
          value={selectedRoleId}
          onChange={(value: any) => setSelectedRoleId(value)}
          menuItems={roles.map(({ id, name }) => ({ label: name, value: id }))}
        />
      </div>
      <TextField
        className="leadFinderLimitField"
        error={!!leadFinderInputError}
        errorText={leadFinderInputError}
        fullWidth={false}
        id={`${user.id}-lead-finder-limit`}
        label="Lead Finder Monthly Search Limit"
        min={0}
        onChange={handleChangeLimit}
        pattern="^\d+"
        step={1}
        type="number"
        value={leadFinderLimit}
      />
      <SelectField
        className="userEmailServiceField"
        id={`${user.id}-emailService`}
        label="Email Service"
        onChange={(value: any) => setEmailService(value)}
        menuItems={[FeatureToggle.ON, FeatureToggle.OFF]}
        value={emailService}
      />
      <SelectField
        className="mileageTrackingServiceField"
        id={`${user.id}-mileageTracking`}
        label="Mileage Tracking"
        onChange={(value: any) => setMileageTracking(value)}
        menuItems={[FeatureToggle.ON, FeatureToggle.OFF]}
        value={mileageTracking}
      />
      <SelectField
        className="activeCheckInField"
        id={`${user.id}-activeCheckIn`}
        label="Ongoing Check-Ins"
        onChange={(value: any) => setActiveCheckIn(value)}
        menuItems={[FeatureToggle.ON, FeatureToggle.OFF]}
        value={activeCheckIn}
      />
    </div>
  );
};
export default OrgUserItems;
