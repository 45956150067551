import React, { FC, ReactText, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, CircularProgress, DialogContainer, FontIcon, TextField } from 'react-md';
import { OrganizationOutput } from '@mmc-csm/shared';
import { connect } from 'react-redux';
import { searchOrgs } from '../../store/guides/guides-actions';
import { ApplicationState } from '../../store';

export interface GuideOrgsProps {
  loading: boolean,
  orgIds: OrganizationOutput['id'][],
  orgs: OrganizationOutput[],
  onSave?: (orgIds: OrganizationOutput['id'][]) => void,
  onClose: () => void;
  onSearch: typeof searchOrgs,
}

const GuideOrgsModal: FC<GuideOrgsProps> = ({ loading, orgIds, orgs, onSave, onClose, onSearch }) => {
  useEffect(() => {
    onSearch({ orgIds, search: '' });
  }, [onSearch, orgIds]);

  const [search, setSearch] = useState('');
  const handleSearchChange = useCallback((value: ReactText) => setSearch(value as string), []);

  const [updatedOrgIds, setUpdatedOrgIds] = useState<OrganizationOutput['id'][]>(orgIds);

  const handleSave = useCallback(() => {
    if (onSave) {
      onSave(updatedOrgIds);
    }
  }, [updatedOrgIds, onSave]);

  const actions = useMemo(
    () => {
      const result = [];
      result.push({ children: 'Cancel', onClick: onClose });
      result.push({ children: 'Save', onClick: handleSave, primary: true });
      return result;
    }, [handleSave, onClose],
  );

  const [buttonState, setButtonState] = useState(false);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      onSearch({ orgIds, search });
      setButtonState(true);
    }
  };

  const handleClick = () => {
    if (!buttonState) {
      onSearch({ orgIds, search });
      setButtonState(true);
    } else {
      setSearch('');
      onSearch({ orgIds, search: '' });
      setButtonState(false);
    }
  };

  const buttonIcon = () => {
    if (buttonState) return <FontIcon>close</FontIcon>;
    return <FontIcon>search</FontIcon>;
  };

  const handleOrgChange = (orgId: OrganizationOutput['id']) => {
    setUpdatedOrgIds(orgIds => {
      if (orgIds.includes(orgId)) {
        return orgIds.filter(id => id !== orgId);
      }
      return [...orgIds, orgId];
    });
  };

  return (
    <DialogContainer
      actions={actions}
      focusOnMount
      id="orgs-to-guide-dialog"
      onHide={() => onClose()}
      title="Assign Orgs to Guide"
      visible
      style={{ zIndex: 999 }}
      width={400}
    >
      <div className="GuideOrgsModal__subTitle">
        All users with assigning abilities from these orgs will be able to see and assign this guide to their users.
      </div>
      <TextField
        id="txt-orgs-search"
        onChange={handleSearchChange}
        value={search}
        inlineIndicator={<Button onClick={handleClick}>{buttonIcon()}</Button>}
        onKeyDown={handleKeyDown}
        fullWidth
        placeholder="Search by Name or Email"
      />
      <div className="GuideOrgsModal__list">
        {loading ? <CircularProgress id="guide-orgs-loading" scale={2} /> : (
          orgs.map(org => (
            <Checkbox
              key={org.id}
              className="GuideOrgsModal__item"
              id={`guide-org-${org.id}`}
              name="guide-org-checkboxes[]"
              label={org.name}
              labelBefore
              checked={updatedOrgIds.includes(org.id)}
              onChange={() => handleOrgChange(org.id)}
            />
          )))}
      </div>
    </DialogContainer>
  );
};

const mapStateToProps = ({ guideDetails }: ApplicationState) => ({
  loading: guideDetails.searchingOrgs,
  orgs: guideDetails.guideSearchOrgs,
});

const mapDispatchToProps = {
  onSearch: searchOrgs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuideOrgsModal);
