import { GuideListInput, GuideListOutput, GuideOutput, OrganizationListOutput, GuideOrgListInput } from '@mmc-csm/shared';
import { action } from 'typesafe-actions';
import { GuideInput } from '@mmc-csm/shared/src';
import { GuidesActionTypes } from './guides-types';

export const listGuides = (input?: GuideListInput) => action(GuidesActionTypes.LIST, input);
export const listGuidesSuccess = (payload: GuideListOutput) => action(GuidesActionTypes.LIST_SUCCESS, payload);
export const listGuidesError = () => action(GuidesActionTypes.LIST_ERROR);

export const selectGuide = (guide: GuideOutput) => action(GuidesActionTypes.SELECT, { guide });
// export const updateGuide = (guide: OrganizationOutput) => action(GuidesActionTypes.UPDATED, { guide });

export const getGuide = (id: number) => action(GuidesActionTypes.GET, { id });
export const getGuideSuccess = (guide: GuideOutput) => action(GuidesActionTypes.GET_SUCCESS, { guide });
export const getGuideError = () => action(GuidesActionTypes.GET_ERROR);

export const createGuide = (input: GuideInput, openGuide?: boolean, callback?: (guide: GuideOutput) => void) => action(GuidesActionTypes.CREATE_GUIDE, { input, callback, openGuide });
export const createGuideSuccess = (guide: GuideOutput) => action(GuidesActionTypes.CREATE_GUIDE_SUCCESS, { guide });
export const createGuideError = () => action(GuidesActionTypes.CREATE_GUIDE_ERROR);

export const updateGuide = (input: GuideOutput, callback?: (updatedGuide: GuideOutput) => void) => action(GuidesActionTypes.UPDATE_GUIDE, { input, callback });
export const updateGuideSuccess = (guide: GuideOutput) => action(GuidesActionTypes.UPDATE_GUIDE_SUCCESS, { guide });
export const updateGuideError = () => action(GuidesActionTypes.UPDATE_GUIDE_ERROR);

export const deleteGuide = (id: GuideOutput['id'], callback?: () => void) => action(GuidesActionTypes.DELETE_GUIDE, { id, callback });
export const deleteGuideSuccess = (id: GuideOutput['id']) => action(GuidesActionTypes.DELETE_GUIDE_SUCCESS, { id });
export const deleteGuideError = () => action(GuidesActionTypes.DELETE_GUIDE_ERROR);

export const listGuideOrgs = (input: GuideOrgListInput) => action(GuidesActionTypes.LIST_ORGS, input);
export const listGuideOrgsSuccess = (payload: OrganizationListOutput) =>
  action(GuidesActionTypes.LIST_ORGS_SUCCESS, payload);
export const listGuideOrgsError = () => action(GuidesActionTypes.LIST_ORGS_ERROR);

export const searchOrgs = (input: GuideOrgListInput) => action(GuidesActionTypes.SEARCH_ORGS, input);
export const searchOrgsSuccess = (payload: OrganizationListOutput) =>
  action(GuidesActionTypes.SEARCH_ORGS_SUCCESS, payload);
export const searchOrgsError = () => action(GuidesActionTypes.SEARCH_ORGS_ERROR);
