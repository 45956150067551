import React, { useState } from 'react';
import { Button, FontIcon, TextField } from 'react-md';

interface GuideOrgsSearchBarProps {
  defaultValue?: string;
  onSearch: (search: string) => void;
  onAssignClick: () => void;
}

export const GuideOrgsSearchBar: React.FC<GuideOrgsSearchBarProps> = props => {
  const [buttonState, setButtonState] = useState(false);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      props.onSearch((e.currentTarget as HTMLInputElement).value);
      setButtonState(true);
    }
  };

  const handleClick = () => {
    const fieldInput = document.getElementById('orgs-search') as HTMLInputElement | null;

    if (!buttonState && fieldInput) {
      props.onSearch(fieldInput.value);
      setButtonState(true);
    } else {
      (document.getElementById('orgs-search') as HTMLInputElement).value = '';
      props.onSearch('');
      setButtonState(false);
    }
  };
  const buttonIcon = () => {
    if (buttonState) return <FontIcon>close</FontIcon>;
    return <FontIcon>search</FontIcon>;
  };

  return (

    <div className="GuideSearchBar">
      <div>
        <h3 className="GuideDetailsSubtitle">
          Orgs
          &nbsp;
          &nbsp;
          <Button primary flat onClick={props.onAssignClick}>Assign</Button>
        </h3>
      </div>
      <div />
      <div className="GuideSearchInput">
        <TextField
          id="orgs-search"
          defaultValue={props.defaultValue}
          inlineIndicator={<Button onClick={handleClick}>{buttonIcon()}</Button>}
          onKeyDown={handleKeyDown}
          fullWidth
          placeholder="Search by Org Name"
        />
      </div>
    </div>
  );
};
