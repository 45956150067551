import qs from 'qs';
import {
  GuideListInput,
  GuideListOutput,
  GuideOrgListInput,
  GuideOutput,
  OrganizationListOutput,
} from '@mmc-csm/shared';
import {GuideInput, GuideOrgAssignmentInput} from '@mmc-csm/shared/src';
import { API } from '../../utils/api';

export class GuideService {
  async getGuides(input?: GuideListInput): Promise<GuideListOutput> {
    const query = qs.stringify(input);

    return API.get<GuideListOutput>(`/guides?${query}`);
  }

  async getGuide(id: number): Promise<GuideOutput> {
    return API.get<GuideOutput>(`/guides/${id}`);
  }

  async createGuide(input: GuideInput): Promise<GuideOutput> {
    return API.post<GuideOutput>('/guides', input);
  }

  async updateGuide(input: GuideOutput): Promise<GuideOutput> {
    return API.put<GuideOutput>(`/guides/${input.id}`, input);
  }

  async deleteGuide(id: GuideOutput['id']): Promise<void> {
    return API.delete(`/guides/${id}`);
  }

  async getGuideOrgs({ orgIds, ...input }: GuideOrgListInput): Promise<OrganizationListOutput> {
    const query = qs.stringify({
      ...input,
      filter: `{"id": {"$in": [${orgIds.join(',')}]}}`,
    });

    return API.get<OrganizationListOutput>(`/organizations?${query}`);
  }

  async searchGuideOrgs({ orgIds, search }: GuideOrgListInput): Promise<OrganizationListOutput> {
    const query = qs.stringify({
      offset: 0,
      limit: 100,
      filter: JSON.stringify({
        $or: [
          search ? { name: { $in: search } } : { id: { $in: orgIds } },
        ],
      }),
    });

    return API.get<OrganizationListOutput>(`/organizations?${query}`);
  }

  async updateAssignment(input: GuideOrgAssignmentInput): Promise<void> {
    return API.put<void>('/guides/assignment', input);
  }
}
