import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { GuidesActionTypes } from './guides-types';
import {
  createGuide, createGuideError, createGuideSuccess, deleteGuide, deleteGuideError, deleteGuideSuccess,
  getGuide,
  getGuideError,
  getGuideSuccess, listGuideOrgs, listGuideOrgsError, listGuideOrgsSuccess,
  listGuides,
  listGuidesError,
  listGuidesSuccess, searchOrgs, searchOrgsError, searchOrgsSuccess,
  selectGuide, updateGuide, updateGuideError, updateGuideSuccess,
} from './guides-actions';
import { GuideService } from './guides-service';
import { handleError } from '../errors/errors-actions';

const service = new GuideService();

function* handleListGuides(action: ReturnType<typeof listGuides>) {
  try {
    const res = yield call(service.getGuides, action.payload);
    yield put(listGuidesSuccess(res));
  } catch (err) {
    yield put(listGuidesError());
    yield put(handleError(err));
  }
}

function* handleSelectGuide(action: ReturnType<typeof selectGuide>) {
  yield put(push(`/adm/guides/${action.payload.guide.id}`));
}

function* handleGetGuide(action: ReturnType<typeof getGuide>) {
  try {
    const res = yield call(service.getGuide, action.payload.id);
    yield put(getGuideSuccess(res));
  } catch (err) {
    yield put(getGuideError());
    yield put(handleError(err));
    yield put(push('/adm/guides'));
  }
}

function* handleCreateGuide(action: ReturnType<typeof createGuide>) {
  try {
    const guide = yield call(service.createGuide, action.payload.input);
    yield put(createGuideSuccess(guide));
    if (action.payload.openGuide) {
      yield put(push(`/adm/guides/${guide.id}`));
    }
    if (action.payload.callback) {
      yield call(action.payload.callback, guide);
    }
  } catch (err) {
    yield put(createGuideError());
    yield put(handleError(err));
  }
}

function* handleUpdateGuide(action: ReturnType<typeof updateGuide>) {
  try {
    const guide = yield call(service.updateGuide, action.payload.input);
    yield put(updateGuideSuccess(guide));
    if (action.payload.callback) {
      yield call(action.payload.callback, guide);
    }
  } catch (err) {
    yield put(updateGuideError());
    yield put(handleError(err));
  }
}

function* handleDeleteGuide(action: ReturnType<typeof deleteGuide>) {
  try {
    yield call(service.deleteGuide, action.payload.id);
    yield put(deleteGuideSuccess(action.payload.id));
    if (action.payload.callback) {
      yield call(action.payload.callback);
    } else {
      yield put(push('/adm/guides'));
    }
  } catch (err) {
    yield put(deleteGuideError());
    yield put(handleError(err));
  }
}

function* handleListGuideOrgs(action: ReturnType<typeof listGuideOrgs>) {
  try {
    const res = yield call(service.getGuideOrgs, action.payload);
    yield put(listGuideOrgsSuccess(res));
  } catch (err) {
    yield put(listGuideOrgsError());
    yield put(handleError(err));
  }
}

function* handleSearchGuideOrgs(action: ReturnType<typeof searchOrgs>) {
  try {
    const res = yield call(service.searchGuideOrgs, action.payload);
    yield put(searchOrgsSuccess(res));
  } catch (err) {
    yield put(searchOrgsError());
    yield put(handleError(err));
  }
}

export function* guidesSaga() {
  yield takeEvery(GuidesActionTypes.LIST, handleListGuides);
  yield takeEvery(GuidesActionTypes.SELECT, handleSelectGuide);
  yield takeEvery(GuidesActionTypes.GET, handleGetGuide);
  yield takeEvery(GuidesActionTypes.CREATE_GUIDE, handleCreateGuide);
  yield takeEvery(GuidesActionTypes.UPDATE_GUIDE, handleUpdateGuide);
  yield takeEvery(GuidesActionTypes.DELETE_GUIDE, handleDeleteGuide);
  yield takeEvery(GuidesActionTypes.LIST_ORGS, handleListGuideOrgs);
  yield takeEvery(GuidesActionTypes.SEARCH_ORGS, handleSearchGuideOrgs);
}
