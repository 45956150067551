import React, { FC, useMemo } from 'react';
import { DialogContainer } from 'react-md';

export interface GuideDeleteConfirmationModalProps {
  guideName: string,
  onCancel: () => void;
  onDelete: () => void;
}

const GuideDeleteConfirmationModal: FC<GuideDeleteConfirmationModalProps> = ({ guideName, onCancel, onDelete }) => {
  const actions = useMemo(() => [
    { primary: true, children: 'Cancel', onClick: onCancel },
    { secondary: true, children: 'Delete', onClick: onDelete },
  ], [onCancel, onDelete]);

  return (
    <DialogContainer
      className="GuideDeleteConfirmationModal"
      id="delete-guide-confirmation-modal"
      onHide={onCancel}
      actions={actions}
      title={`Delete ${guideName ? `"${guideName}"` : 'this guide'}?`}
      portal
      visible
    />
  );
};

export default GuideDeleteConfirmationModal;
